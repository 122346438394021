.App {
  text-align: left;
}

a:link {

  text-decoration: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999EC0;
  opacity: 1; /* Firefox */
}

@font-face {
  font-family: 'abc_arizona_mixlight';
  src: url('./assets/fonts/abcarizonamix-light-webfont.woff2') format('woff2'),
       url('./assets/fonts/abcarizonamix-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_novaregular';
  src: url('./assets/fonts/proxima-nova-font-webfont.woff2') format('woff2'),
       url('./assets/fonts/proxima-nova-font-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "ABC Arizona Mix";
  src: url(./assets/fonts/ABCArizonaMix-Light.otf);
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/fonts/Proxima-Nova-Font.otf);
  font-style: normal;
}
input::placeholder {
  color: #999EC0;
  opacity: 1;
}